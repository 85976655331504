"use strict";

// This file defines role-based access to reports and other frontend views.
// These definitions are used by the backend reportingService to send a correct
// list of reports to the frontend, and also by the frontend to determine what
// views should be accessible. Neither is critical to security since access to
// the actual data is determined in the backend by other means.

// For each report or other frontend view, the exported object has
// - a key that corresponds to
//   - the value of view in ../backend/services/reportingService.js (if present)
//   - the value of name in ../frontend/src/modules/reporting/routes/routes*.js
// - a value that is a "role test function". It determines whether a user has
//   access to the view in question by
//   - taking an argument that is an object {role:true} for the roles of the
//     current user
//   - returning truthy if and only if the user should be granted access

// Reusable role test functions:
/* eslint-disable no-unused-vars */
const {
  // Role test functions to give access to
  // - Fujitsu's developers
  // - Fujitsu's testers
  dev,
  dev_category_A,
  dev_category_B,
  dev_category_C,
  dev_category_D,
  dev_category_E,
  dev_category_F,
  dev_category_G,
  dev_category_H,
  dev_category_I,
  dev_category_J,
  // Role test functions to give access to
  // - CoS testers
  // - Fujitsu's developers
  // - Fujitsu's testers
  test,
  test_category_A,
  test_category_B,
  test_category_C,
  test_category_D,
  test_category_E,
  test_category_F,
  test_category_G,
  test_category_H,
  test_category_I,
  test_category_J,
  // Role test functions for access by all eligible users and no others
  allowall,
  category_A,
  category_B,
  category_C,
  category_D,
  category_E,
  category_F,
  category_G,
  category_H,
  category_I,
  category_J,
} = require("./roleTestFunctions.js");
/* eslint-enable no-unused-vars */

module.exports = Object.freeze({
  // Listed in alphabetic order

  // Used only for frontend routes:
  MyAccount: allowall, // todo should ANV be required?
  ReleaseNotes: allowall, // todo should ANV be required?
  Reporting: allowall, // todo should ANV be required?
  Unauthorized: allowall,

  // Used both for frontend routes and backend reportingService:
  Ad1: category_A,
  Ad1_1: category_A,
  Ad1_2: category_A,
  Ad1_3: category_A,
  Ad1_4: category_A,
  Ad2: category_A,
  Ad2_1: category_A,
  Ad2_2: category_A,
  Ad2_3: category_A,
  Ad2_4: category_A,
  Ad2_5: category_A,
  Ad2_6: category_A,
  Ad2_7: category_A,
  Ad2_8: category_A,
  Ad2_9: category_A,
  Ad2_10: category_A,
  Ad2_11: category_A,
  Ad2_12: category_A,
  Ad2_13: category_A,
  Ad2_14: category_A,
  Ad3: category_A,
  Ad4: category_A,
  Ad4_1: category_A,
  Ad4_2: category_A,
  Ad4_3: category_A,
  Ad4_4: category_A,
  Ad4_5: category_A,
  Ad4_6: category_A,
  Ad5: category_A,
  Ad6: category_A,
  Ad6_1: category_A,
  Ad6_2: category_A,
  Ad7: category_B,
  Ad8: category_B,
  Ad8_1: category_B,
  Ad9: category_H,
  Cr1: category_B,
  "Cr2-1": category_B,
  "Cr2-2": category_B,
  Cr3: category_A,
  Cr4: category_A,
  Cr5: category_A,
  Cr6: category_A,
  Lev2: category_G,
  Lev3: category_G,
  Lev4: dev_category_G,
  Lev6: category_G,
  Sccm1: category_D,
  Sccm2: category_D,
  Sccm3: category_D,
  Sccm4: category_D,
  Dmnx1: category_B,
  Dmnx2: category_B,
  Dmnx3: category_I,
  Mdm1: category_B,
  Mdm2: category_B,
  Mdm3: category_B,
  Mdm4: category_B,
  Mdm5: category_C,
  Mdm6: category_B,
  Mdm7: category_B,
});
