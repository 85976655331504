<template>
  <div>
    <div class="container space-top">
      <div class="row-1-col">
        <div class="row-item">
          <div class="page-header">
            <h1>LEV-4 Stöd för en användare</h1>
          </div>
          <div class="row-flex space-top-sm">
            <RFilter configName="veda" v-model="serverFilters.veda" />
            <!-- <RFilter configName="user_dn" v-model="serverFilters.dn" /> -->
            <RFilter configName="user_uid" v-model="serverFilters.uid" />
            <RFilter name="Namn" component="TextFilter" width="350" qpsEncodingMethod="Straight" qpsParamName="dpn" :pattern="/^.{1,1000}$/" v-model="serverFilters.displayName" />
          </div>
        </div>
      </div>
      <!-- <div class="row-flex">
        <a v-show="showLoadButton" class="button-standard noselect" @click="updateData">Hämta data</a>
      </div>-->
      <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
      <span v-if="notSingle">{{ notSingleMessage }}</span>
      <div v-if="showData">
        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TableSingleObject :tableData="UserTableData" :fields="UserColumns" :csvFilename="csvFilename_single" />
        </div>
        <div class="row-1-col space-top">
          <h2>Roller</h2>
          <TablePaginated :tableData="RolesTableData" :columns="RolesColumns" :disableFiltering="false" :csvFilename="csvFilename_table" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "Lev4",
  props: { endpoint: String },
  data() {
    return {
      isLoading: false,
      UserTableData: null,
      RolesTableData: null,

      crossfilter: null,
      autoload: true,
      tableDataIsFor: null,
      RolesColumns: [
        {
          property: "Role",
          displayName: "Roll",
          show: true,
        },
        {
          property: "RoleName",
          displayName: "Rollnamn",
          show: true,
        },
        {
          property: "UserName",
          displayName: "Namn",
          show: true,
        },
        {
          property: "UserUID",
          displayName: "AnvändarUID",
          show: true,
        },
      ],
      UserColumns: [
        {
          property: "UserName",
          displayName: "Namn",
          show: true,
        },
        {
          property: "UserUID",
          displayName: "Användare UID",
          show: true,
        },
        {
          property: "CompanyId",
          displayName: "FörvaltningsId",
          show: false,
        },
        {
          property: "Company",
          displayName: "Förvaltning",
          show: true,
        },
        {
          property: "DepartmentId",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "Department",
          displayName: "Verksamhet",
          show: true,
        },
        // {
        //   property: "VDC",
        //   displayName: "Lönekostnadsställe",
        //   show: true,
        // },
        {
          property: "FakturaRef",
          displayName: "Fakturareferens",
          show: true,
        },
      ],
      filters: {},
      serverFilters: {
        veda: [],
        // dn: "",
        uid: "",
        displayName: "",
      },
    };
  },
  watch: {
    async "serverFilters.veda"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.displayName"() {
      await this.maybeUpdateData();
    },
    async "serverFilters.uid"() {
      await this.maybeUpdateData();
    },
  },
  methods: {
    async maybeUpdateData() {
      if (this.autoload && _.size(_.pickBy(this.serverFilters)) > 0 && !_.isEqual(this.serverFilters, this.tableDataIsFor)) {
        await this.updateData();
      }
    },
    async updateData() {
      const currentFilters = _.cloneDeep(this.serverFilters);
      const reqParams = {};

      if (currentFilters.veda.length) {
        reqParams.veda_ids = currentFilters.veda;
      }
      if (currentFilters.displayName) {
        reqParams.dpn = currentFilters.displayName;
      }
      if (currentFilters.uid) {
        reqParams.uid = currentFilters.uid;
      }

      this.isLoading = true;
      const response = await this.$http.get("/api/reporting/delivery/usersupport", { params: reqParams });
      // Make it possible to filter on boolean values
      this.UserTableData = response.data;
      this.RolesTableData = _.isString(response.data) ? [] : response.data.roles;
      this.tableDataIsFor = currentFilters;
      this.isLoading = false;
    },
  },
  computed: {
    // showData() { //Ursprunglig
    //   return _.isEqual(this.serverFilters, this.tableDataIsFor);
    // },
    notSingle() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor) && (this.UserTableData === "none" || this.UserTableData === "too many");
    },
    showData() {
      return _.isEqual(this.serverFilters, this.tableDataIsFor) && _.isObject(this.UserTableData);
    },
    notSingleMessage() {
      if (this.UserTableData === "none") {
        return "Inget resultat";
      }
      if (this.UserTableData === "too many") {
        return "För många användare";
      }
      return "";
    },
    // showLoadButton() {
    //   return (
    //     !_.isEqual(this.serverFilters, this.tableDataIsFor) && !this.autoload
    //   );
    // },
    // notFound() {
    //   return !this.UserTableData;
    // },
    csvFilename_single() {
      return `LEV-4 Export - Användare ${this.UserTableData.UserUID}`;
    },
    csvFilename_table() {
      return `LEV-4 Export - Stöd för användare ${this.UserTableData.UserUID}`;
    },
  },
  async mounted() {
    // Do nothing
  },
};
</script>
