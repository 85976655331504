
import MyAccount from "../views/MyAccount.vue";
import ReleaseNotes from "../views/ReleaseNotes.vue";
import Unauthorized from "../views/Unauthorized.vue";

export default function () {
  return [
    {
      path: "/myaccount",
      name: "MyAccount",
      component: MyAccount,
    },
    {
      path: "/releasenotes",
      name: "ReleaseNotes",
      component: ReleaseNotes,
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      component: Unauthorized,
      meta: { allowWithoutLogin: true },
    },
  ];
}
