"use strict";

// The array of existing roles.
// A role is {code, type, title}, where
// - code is a short uppercase abbreviation reporesenting the role.
// - type is one of
//   - "Central":         The role applies to all data of the type the role
//                        gives access to.
//   - "Lokal":           The role applies to data belonging to a certain Veda
//                        ID.
//   - "Fakturareferens": The role applies to data related to a certain invoice
//                        reference, and in some cases to data belonging to the
//                        Veda ID that owns the specified invoice reference.
//   - "Ingen":           The role exists but will not be used in the reporting
//                        application. Present in case that changes.
// - title is the full name of the role.

const futile = require("@fujitsusweden/futile");

exports.roles_array = futile.deepFreeze([
  // Added as a mocked role for dev only.
  {
    code: "FJDEV",
    type: "Central",
    title: "Fujitsu testanvändare i utvecklingsmiljö",
  },

  // Added as a mocked role for CoS test users in production.
  {
    code: "COSTEST",
    type: "Central",
    title: "CoS testanvändare med produktionsdata",
  },

  // Added as a mocked role for dev, and by some AD group.
  {
    code: "IMPERSONATE",
    type: "Central",
    title: "Felsökare med rättighet att se rapporter från annan användares perspektiv",
  },

  // CoS roles
  { code: "ANV", type: "Lokal", title: "Användare" },
  { code: "APP", type: "Ingen", title: "Applikationsägare" },
  { code: "AVH", type: "Ingen", title: "Akut vikariehantering" },
  { code: "BA", type: "Lokal", title: "Behörighetsadministratör" },
  { code: "BB", type: "Fakturareferens", title: "Behörig Beställare" },
  { code: "BG", type: "Fakturareferens", title: "Behörig Godkännare" },
  { code: "BOA", type: "Central", title: "Behörighet och användarkonton" },
  { code: "CA", type: "Central", title: "Central applikationsförvaltare" },
  { code: "CAS", type: "Central", title: "Central administratör staden" },
  { code: "CIS", type: "Central", title: "Centralt IT-stöd" },
  {
    code: "CSS",
    type: "Central",
    title: "Central informationssäkerhetssamordnare",
  },
  { code: "CUS", type: "Central", title: "Central uppföljning" },
  { code: "EKA", type: "Lokal", title: "Ekonomiadministratör" },
  { code: "ELEV", type: "Ingen", title: "Elev" },
  { code: "HTKA", type: "Ingen", title: "Huvudtjänstekortsadministratör" },
  { code: "ITKP", type: "Lokal", title: "IT-Kontaktperson" },
  { code: "ITVA", type: "Lokal", title: "IT-Verksamhetsansvarig" },
  { code: "LIS", type: "Lokal", title: "Lokalt IT-stöd" },
  {
    code: "LSS",
    type: "Lokal",
    title: "Lokal informationssäkerhetssamordnare",
  },
  { code: "LU", type: "Lokal", title: "Lokal uppföljning" },
  { code: "RES", type: "Central", title: "Resursägare" },
  { code: "RSA", type: "Lokal", title: "Resursansvarig" },
  { code: "SA", type: "Lokal", title: "Skoladministratör" },
  { code: "TKA", type: "Ingen", title: "Tjänstekortsadministratör" },
  { code: "TS", type: "Ingen", title: "Telefonisamordnare" },
  { code: "UBA", type: "Lokal", title: "Utbytesansvarig" },
]);

exports.roles_dict = futile.deepFreeze(futile.indexBy(exports.roles_array, "code"));
exports.roles_category_A = futile.deepFreeze(["BA", "BB", "BG", "BOA", "CAS", "CIS", "CSS", "CUS", "ITKP", "ITVA", "LSS", "LU", "SA", "UBA"]);
exports.roles_category_B = futile.deepFreeze(["BA", "BB", "BG", "BOA", "CAS", "CIS", "CSS", "CUS", "EKA", "ITKP", "ITVA", "LSS", "LU", "SA", "UBA"]);
exports.roles_category_C = futile.deepFreeze(["CAS", "CIS", "CUS"]);
exports.roles_category_D = futile.deepFreeze(["BA", "BB", "BG", "CA", "CAS", "CIS", "CUS", "ITKP", "ITVA", "LU", "RES", "RSA", "SA"]);
exports.roles_category_E = futile.deepFreeze(["BA", "BB", "BG", "BOA", "CAS", "CIS", "CUS", "EKA", "ITKP", "ITVA", "LIS", "LU", "RES", "SA", "UBA"]);
exports.roles_category_F = futile.deepFreeze(["BA", "BB", "BG", "BOA", "CAS", "CIS", "CUS", "EKA", "ITKP", "ITVA", "LIS", "LU", "SA"]);
exports.roles_category_G = futile.deepFreeze(["ANV", "BA", "BB", "BG", "BOA", "CAS", "CIS", "CUS", "EKA", "ITKP", "ITVA", "LIS", "LU", "RES", "SA", "UBA"]);
exports.roles_category_H = futile.deepFreeze(["CAS"]);
exports.roles_category_I = futile.deepFreeze(["ITKP", "ITVA", "LU", "EKA", "CAS", "CIS"]);
exports.roles_category_J = futile.deepFreeze(["ITKP", "ITVA", "BA", "BB", "BG", "CAS"]);

function union(...args) {
  let items = [];
  for (const arr of args) {
    items = [...items, ...arr];
  }
  const ret = [...new Set(items)];
  ret.sort();
  return ret;
}

exports.roles_veda = futile.deepFreeze(
  union(
    exports.roles_category_A, // Since veda filter is used in AD and CR
    exports.roles_category_B, // Since veda filter is used in AD, CR, IN
    // exports.roles_category_C, // Veda not needed in IN-5
    exports.roles_category_D, // Since veda filter is used in SCCM
    // exports.roles_category_E, // BEST reports removed
    // exports.roles_category_F, // Veda not needed in FAKT-1
    exports.roles_category_G, // Since veda filter is used in LEV
    exports.roles_category_H, // Since veda filter is used in AD9
    exports.roles_category_J, // Since veda filter is used in Devices1
  ),
);
