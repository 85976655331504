"use strict";
const roles = require("./roles.js");

// Role test functions used by
// - ./authorizeRoles.js
// - ../backend/middlewares/authorizationRules/

// Allow FJ test user in dev, and customer's test users in production
exports.test = ro => ro.FJDEV || ro.COSTEST;

// Allow only FJ test user in dev
exports.dev = ro => ro.FJDEV;

// Allow anyone
exports.allowall = ignored__ro => true;

// Access categories.
// - test_category_X requires COSTEST access AND a role in the category.
// - category_X is meant for production that would allow all users with an
//   appropriate role access, with oru without COSTEST.

exports.category_A = ro => roles.roles_category_A.some(r => ro[r]);
exports.category_B = ro => roles.roles_category_B.some(r => ro[r]);
exports.category_C = ro => roles.roles_category_C.some(r => ro[r]);
exports.category_D = ro => roles.roles_category_D.some(r => ro[r]);
exports.category_E = ro => roles.roles_category_E.some(r => ro[r]);
exports.category_F = ro => roles.roles_category_F.some(r => ro[r]);
exports.category_G = ro => roles.roles_category_G.some(r => ro[r]);
exports.category_H = ro => roles.roles_category_H.some(r => ro[r]);
exports.category_I = ro => roles.roles_category_I.some(r => ro[r]);
exports.category_J = ro => roles.roles_category_J.some(r => ro[r]);

exports.dev_category_A = ro => exports.dev(ro) && exports.category_A(ro);
exports.dev_category_B = ro => exports.dev(ro) && exports.category_B(ro);
exports.dev_category_C = ro => exports.dev(ro) && exports.category_C(ro);
exports.dev_category_D = ro => exports.dev(ro) && exports.category_D(ro);
exports.dev_category_E = ro => exports.dev(ro) && exports.category_E(ro);
exports.dev_category_F = ro => exports.dev(ro) && exports.category_F(ro);
exports.dev_category_G = ro => exports.dev(ro) && exports.category_G(ro);
exports.dev_category_H = ro => exports.dev(ro) && exports.category_H(ro);
exports.dev_category_I = ro => exports.dev(ro) && exports.category_I(ro);
exports.dev_category_J = ro => exports.dev(ro) && exports.category_J(ro);

exports.test_category_A = ro => exports.test(ro) && exports.category_A(ro);
exports.test_category_B = ro => exports.test(ro) && exports.category_B(ro);
exports.test_category_C = ro => exports.test(ro) && exports.category_C(ro);
exports.test_category_D = ro => exports.test(ro) && exports.category_D(ro);
exports.test_category_E = ro => exports.test(ro) && exports.category_E(ro);
exports.test_category_F = ro => exports.test(ro) && exports.category_F(ro);
exports.test_category_G = ro => exports.test(ro) && exports.category_G(ro);
exports.test_category_H = ro => exports.test(ro) && exports.category_H(ro);
exports.test_category_I = ro => exports.test(ro) && exports.category_I(ro);
exports.test_category_J = ro => exports.test(ro) && exports.category_J(ro);
