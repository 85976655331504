<!--
     A value can be a string, an array of strings, or an object with string
     values. The columns prop is an array of column configurations. The onclick
     property of a column configuration is similar to how it works in
     TableSingleObject.

     If there is more than one table on the page, make the paramPrefix prop
     differ between them.
-->

<template>
  <div class="space-bottom">
    <QueryParamSync encodingMethod="Integer" :paramName="paramPrefix + 'page'" :default="1" v-model="paging.currentPage" />
    <QueryParamSync :encodingMethod="columnFilterEncodingMethod" :paramName="paramPrefix + 'filters'" :default="{}" v-model="columnFilters" />
    <QueryParamSync :encodingMethod="columnOrderEncodingMethod" :paramName="paramPrefix + 'order'" v-model="columnOrder" />
    <QueryParamSync :encodingMethod="columnVisibilityEncodingMethod" :paramName="paramPrefix + 'visible'" v-model="columnVisibility" />
    <QueryParamSync encodingMethod="Straight" :paramName="paramPrefix + 'sort'" v-model="activeSortByProperty" />
    <QueryParamSync encodingMethod="Straight" :paramName="paramPrefix + 'sortorder'" v-model="sortOrder" />
    <table class="block-table table-resizable">
      <thead>
        <tr>
          <th colspan="100%" style="font-weight: normal; !important; background-color: #FFFFFF !important;">
            <div class="row-flex">
              <a class="button-standard noselect" v-on:click="toggleDisplayShowColumnsHeader()"> <span v-show="displayShowColumnsHeader">⨉</span>Kolumner </a>
              <JsonCSVExcel :data="TableDataFiltered" :name="csvFilename + '.csv'" :columns="columns">
                <a class="button-standard noselect">Exportera till Excel</a>
              </JsonCSVExcel>
            </div>
          </th>
        </tr>
        <tr v-show="displayShowColumnsHeader">
          <th colspan="100%" style="font-weight: normal !important">
            <draggable tag="div" :list="columnOrder" class="row-flex" handle=".drag-handle">
              <div class="row-flex" v-for="(column, index) in columnsData" v-bind:key="'showProperty' + index" style="margin-right: 20px">
                <CheckBox
                  class="drag-handle noselect"
                  style="position: relative; top: 2px; margin-right: 6px"
                  @click="ToggleColumnVisibility(column.property)"
                  :value="columnVisibility.includes(column.property)"
                  :display="column.displayName"
                />
              </div>
            </draggable>
          </th>
        </tr>
        <tr>
          <th v-for="(column, index) in columnsData" v-bind:key="'tableHeader' + index" v-show="column.show">
            <a class="pointer" :class="sortedClass(column.property)" v-on:click="setSortByProperty(column.property)">{{ column.displayName }}</a>
            <br />
            <a v-if="column.enableExpand" class="link" style="font-weight: normal !important" v-on:click="toggleExpandAllCell('expand' + column.property)">
              <template v-if="!columnHeaderExpandState('expand' + column.property)">Expandera alla</template>
              <template v-else>Minimera alla</template>
            </a>
          </th>
        </tr>
        <tr>
          <th v-for="column of columnsData" v-bind:key="'tableFilter' + column.property" v-show="column.show">
            <input
              type="text"
              class="input-sm"
              :value="columnFilters[column.property]"
              @input="
                columnFilters = {
                  ...columnFilters,
                  [column.property]: $event.target.value,
                }
              "
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rowsShown" v-bind:key="'tr' + index">
          <td v-for="(column, index) in columnsData" v-bind:key="'tableRow' + index" v-show="column.show">
            <template v-if="isObjectButNotArray(row[column.property])">
              <ul>
                <li v-for="(objKey, index) in Object.keys(row[column.property])" v-bind:key="'tdObjectLi' + index">
                  <b>{{ objKey }}</b
                  >:
                  <template v-if="column.onclick">
                    <a
                      class="link"
                      @click="
                        column.onclick({
                          root: $root,
                          object: row,
                          property: column.property,
                          value: row[column.property],
                          clickedValue: row[column.property][objKey],
                          key: objKey,
                          propconf: column,
                        });
                        $event.preventDefault();
                      "
                      >{{ vrender(row[column.property][objKey]) }}</a
                    >
                  </template>
                  <template v-else>{{ vrender(row[column.property][objKey]) }}</template>
                </li>
              </ul>
            </template>
            <template v-else-if="isArray(row[column.property])">
              <ul>
                <li
                  v-for="(listObj, index) in row['expand' + column.property] !== undefined && row[column.property].length > 2
                    ? row['expand' + column.property]
                      ? row[column.property]
                      : row[column.property].slice(0, 2)
                    : row[column.property]"
                  v-bind:key="'ul' + index"
                >
                  <template v-if="column.onclick">
                    <a
                      class="link"
                      @click="
                        column.onclick({
                          root: $root,
                          object: row,
                          property: column.property,
                          value: row[column.property],
                          clickedValue: row[column.property][index],
                          key: index,
                          propconf: column,
                        });
                        $event.preventDefault();
                      "
                      >{{ vrender(listObj) }}</a
                    >
                  </template>
                  <template v-else>{{ vrender(listObj) }}</template>
                </li>
              </ul>
              <template v-if="row['expand' + column.property] !== undefined && row[column.property].length > 2">
                <div style>
                  <br />
                  <template v-if="row['expand' + column.property]">
                    <a v-on:click="toggleExpandCell(row, 'expand' + column.property)" class="link">Minimera</a>
                  </template>
                  <template v-else>
                    <a v-on:click="toggleExpandCell(row, 'expand' + column.property)" class="link">Expandera</a>
                    ({{ row[column.property].length - 2 }})
                  </template>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-if="column.onclick">
                <a
                  class="link"
                  @click="
                    column.onclick({
                      root: $root,
                      object: row,
                      property: column.property,
                      value: row[column.property],
                      clickedValue: row[column.property],
                      propconf: column,
                    });
                    $event.preventDefault();
                  "
                  >{{ vrender(row[column.property]) }}</a
                >
              </template>
              <template v-else>{{ vrender(row[column.property]) }}</template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paging-container space-top">
      <div class="noselect" v-on:click="paging.currentPage > 1 ? ChangePage(paging.currentPage - 1) : null">‹</div>
      <template v-for="i in navPages">
        <div class="noselect" v-if="i > 0" v-bind:key="i" v-bind:class="[i === paging.currentPage ? 'paging-link-active' : '']" v-on:click="ChangePage(i)">
          {{ i }}
        </div>
        <div v-else :key="i">…</div>
      </template>
      <div class="noselect" v-on:click="paging.currentPage < numberOfPages ? ChangePage(paging.currentPage + 1) : null">›</div>
      <span class="paging-displaytext">{{ pagingDisplayText }}</span>
    </div>
  </div>
</template>

<script>

import * as _ from "lodash";
import CrossfilterHandler from "../../utils/crossfilter/crossfilterHandler.js";

export default {
  name: "TablePaginated",
  props: {
    disableFiltering: {
      type: Boolean,
      default: true,
    },
    csvFilename: {
      type: String,
      default: `Data ${new Date().toISOString()}`,
    },
    tableData: Array,
    columns: Array,
    paramPrefix: {
      type: String,
      default: "tbl",
    },
  },
  data() {
    return {
      TableDataFiltered: [],
      crossfilter: null,
      displayShowColumnsHeader: false,
      activeSortByProperty: "",
      sortOrder: "",
      paging: {
        rowsPerPage: 20,
        currentPage: 1,
      },
      columnFilters: {},
      currentColumnFilters: {},
      columnOrder: [], // See columnOrderEncodingMethod for default
      columnVisibility: [], // See columnVisibilityEncodingMethod for default,
      expandAllColumnState: [], // Hold the state for exapnd on column level
    };
  },
  methods: {
    vrender(value) {
      if (_.isString(value) || _.isInteger(value)) {
        return value;
      }
      if (value === true) {
        return "Sant";
      }
      if (value === false) {
        return "Falskt";
      }
      return "";
    },

    toggleExpandCell(row, prop) {
      row[prop] = !row[prop];
      this.crossfilter.removeRow(row.cfId);
      this.crossfilter.addRow(row);
      this.setTableDataFiltered();
    },

    // When toggling all cells it's faster to re-create the cf instance rather than updating all rows with existing indexes
    toggleExpandAllCell(prop) {
      this.tableData.forEach(row => {
        row[prop] = !this.expandAllColumnState.filter(item => item.key === prop)[0].value;
      });
      this.expandAllColumnState.filter(item => item.key === prop)[0].value = !this.expandAllColumnState.filter(item => item.key === prop)[0].value;
      this.initCrossfilter(this.tableData);
      this.setTableDataFiltered();
    },

    columnHeaderExpandState(prop) {
      return this.expandAllColumnState.filter(item => item.key === prop)[0].value;
    },

    initTableData() {
      this.columns
        .filter(column => column.enableExpand)
        .forEach(column => {
          this.tableData.forEach(row => {
            row[`expand${column.property}`] = false;
          });
          this.expandAllColumnState.push({
            key: [`expand${column.property}`],
            value: false,
          });
        });
    },

    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.tableData);
      this.columns.forEach(column => {
        this.crossfilter.createDimensionByProperty(column.property, column.property);
      });
    },

    toggleDisplayShowColumnsHeader() {
      this.displayShowColumnsHeader = !this.displayShowColumnsHeader;
    },

    setSortByProperty(property) {
      if (this.activeSortByProperty === property) {
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else if (this.sortOrder === "desc") {
          this.sortOrder = "";
          this.activeSortByProperty = "";
        }
      } else {
        this.activeSortByProperty = property;
        this.sortOrder = "asc";
      }
      this.setTableDataFiltered();
    },

    sortedClass(property) {
      if (this.activeSortByProperty === property) {
        if (this.sortOrder === "asc") {
          return "sorted-asc";
        } else if (this.sortOrder === "desc") {
          return "sorted-desc";
        }
      }
      return "";
    },

    setTableDataFiltered() {
      // Only after crossfilter is initialized
      if (this.crossfilter === null) {
        return;
      }
      // Update filters
      this.columns.forEach(column => {
        const property = column.property;
        const newfilter = this.columnFilters[property];
        const curfilter = this.currentColumnFilters[property];
        if (curfilter !== newfilter) {
          this.crossfilter.filterDimension(property, newfilter);
          this.currentColumnFilters[property] = newfilter;
        }
      });

      // Update this.TableDataFiltered depending on sorting
      if (this.sortOrder === "") {
        this.TableDataFiltered = this.crossfilter.allFiltered();
      } else {
        // if sortorder asc/desc choosen, create sort dimension
        this.crossfilter.generateSortDimension(this.activeSortByProperty);
        if (this.sortOrder === "asc") {
          this.TableDataFiltered = this.crossfilter.getSortDimension(this.activeSortByProperty).bottom(Infinity);
        } else if (this.sortOrder === "desc") {
          this.TableDataFiltered = this.crossfilter.getSortDimension(this.activeSortByProperty).top(Infinity);
        }
      }
    },

    ChangePage(page) {
      this.paging.currentPage = page;
    },

    checkPaging() {
      let cur = this.paging.currentPage;
      const np = this.numberOfPages;
      if (np < cur) {
        cur = np;
      }
      if (cur < 1) {
        cur = 1;
      }
      if (this.paging.currentPage !== cur) {
        this.paging.currentPage = cur;
      }
    },

    ToggleColumnVisibility(property) {
      let vis = new Set(this.columnVisibility);
      if (vis.has(property)) {
        vis.delete(property);
      } else {
        vis.add(property);
      }
      vis = [...vis];
      vis.sort();
      this.columnVisibility = vis;
    },

    isObjectButNotArray: value => _.isObject(value) && !_.isArray(value),

    isArray: value => _.isArray(value),
  },

  computed: {
    rowsShown() {
      const start = (this.paging.currentPage - 1) * this.paging.rowsPerPage;
      const end = start + parseInt(this.paging.rowsPerPage);
      return this.TableDataFiltered.slice(start, end);
    },

    numberOfPages() {
      return Math.ceil(this.TableDataFiltered.length / this.paging.rowsPerPage);
    },

    navPages() {
      const current = this.paging.currentPage;
      const n = this.numberOfPages;
      /* eslint-disable no-magic-numbers */
      if (n <= 6) {
        return _.range(1, n + 1);
      }
      if (current < 5) {
        return [1, 2, 3, 4, 5, 0, n];
      }
      if (current > n - 4) {
        return [1, 0, n - 4, n - 3, n - 2, n - 1, n];
      }
      /* eslint-enable no-magic-numbers */
      return [1, 0, current - 1, current, current + 1, 0, n];
    },

    pagingDisplayText() {
      const len = this.TableDataFiltered.length;
      const cur = this.paging.currentPage;
      const rows = this.paging.rowsPerPage;
      const start = (cur - 1) * rows + 1;
      const end = Math.min(len, cur * rows);
      if (len === 0) {
        return "Inga rader att visa";
      }
      return `Visar ${start} till ${end} av totalt ${len} rader`;
    },

    // Encode filter values in a strange, undocumented format, mainly to avoid
    // too many ugly characters.
    columnFilterEncodingMethod() {
      const def = {};
      function encode(obj) {
        let ret = [];
        const keys = _.keys(obj);
        keys.sort();
        for (const key of keys) {
          const val = obj[key];
          if (_.isString(val) && val.length) {
            ret.push(key);
            ret.push(val);
          }
        }
        ret = JSON.stringify(ret);
        ret = ret.slice(1, ret.length - 1);
        return ret;
      }
      function decode(encodedVals) {
        let lst = `[${encodedVals}]`;
        lst = JSON.parse(lst);
        const ret = {};
        while (lst.length) {
          const prop = lst.shift();
          const val = lst.shift();
          if (!(_.isString(prop) && _.isString(val))) {
            // Silently ignore bad data
            continue;
          }
          ret[prop] = val;
        }
        return ret;
      }
      return { encode, decode, default: def };
    },

    columnOrderEncodingMethod() {
      const def = _.range(this.columns.length);
      function isValid(val) {
        return _.isEqual(_.sortBy(val), def);
      }
      function encode(val) {
        if (!isValid(val)) {
          return encode(def);
        }
        return val.join(",");
      }
      function decode(val) {
        const ret = _.map(`${val}`.split(","), x => parseInt(x));
        if (!isValid(ret)) {
          return _.clone(def);
        }
        return ret;
      }
      return { encode, decode, default: def };
    },

    columnVisibilityEncodingMethod() {
      // Build a sorted array `all` of the property names of all columns, and a
      // sorted array `def` of the property names of the columns that are shown
      // by default.
      const all = [],
        def = [];
      for (const col of this.columns) {
        all.push(col.property);
        if (col.show) {
          def.push(col.property);
        }
      }
      all.sort();
      def.sort();
      // Encode the visible column names as comma-separated strings of property
      // names that differ from the default, with a ~ or - prepended for adding
      // and subtracting visibility, respectively. (It would've been clearer
      // with a + but it doesn't encode nicely.) Technically, the prefix isn't
      // needed since a toggle would carry all necessary information, but the
      // prefix helps to make bookmarked URIs act more similarly across versions
      // where the default differs.
      // ASSUMPTION: No property name includes a comma character.
      function encode(val) {
        let ret = [];
        for (const prop of all) {
          const shownByDefault = def.includes(prop);
          const shown = val.includes(prop);
          if (shown === shownByDefault) {
            continue;
          }
          ret.push((shown ? "~" : "-") + prop);
        }
        ret = ret.join(",");
        return ret;
      }
      function decode(val) {
        let ret = new Set(def);
        for (const patch of val.split(",")) {
          const op = patch.slice(0, 1); // First character should be + or -
          const prop = patch.slice(1); // The rest is the property name
          if (!(["~", "-"].includes(op) && all.includes(prop))) {
            // Silently ignore bad data
            continue;
          }
          if (op === "~") {
            ret.add(prop);
          } else {
            ret.delete(prop);
          }
        }
        ret = [...ret]; // Convert to array
        ret.sort();
        return ret;
      }
      return { encode, decode, default: def };
    },

    columnsData() {
      return _.map(this.columnOrder, idx => {
        const col = this.columns[idx];
        return {
          ...col,
          show: this.columnVisibility.includes(col.property),
        };
      });
    },
  },
  watch: {
    tableData() {
      this.currentColumnFilters = {};
      this.initTableData();
      this.initCrossfilter();
      this.setTableDataFiltered();
    },
    TableDataFiltered() {
      this.checkPaging();
    },
    "paging.currentPage"() {
      this.checkPaging();
    },
    columnFilters() {
      this.setTableDataFiltered();
    },
  },
  mounted() {
    this.initTableData();
    this.initCrossfilter();
    this.setTableDataFiltered();
  },
};
</script>

<style scoped>
.drag-handle {
  cursor: move;
}

.sorted-asc::after {
  content: " ▲";
}

.sorted-desc::after {
  content: " ▼";
}

.paging-container {
  display: flex;
  flex-direction: row;
}

.paging-container > div {
  border: solid 1px #dcdcdc;
  border-left: none;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: #5d237d;
  cursor: pointer;
}

.paging-container > div:first-child {
  border-left: solid 1px #dcdcdc;
}

.paging-link-active {
  background-color: #ebd6f2;
}

@media (min-width: 768px) {
  .paging-displaytext {
    margin-left: auto;
  }
}
</style>
