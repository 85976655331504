"use strict";

// This file holds the configuration information for named filters. The key is
// the configName prop of <RFilter> and the value holds the following
// configuration information:
// - name: Mandatory. The label displayed to the user.
// - component: Mandatory. What component to wrap. Must be "ValueFilter",
//     "TextFilter" or "SelectFilter".
// - nodes: Optional. The nodes to show in a <ValueFilter>.
// - nodeEndpoint: Mandatory if component is "ValueFilter" and nodes is not
//     given. The URI used to fetch the nodes to show in a <ValueFilter>.
// - width: The width of the component.
// - qpsEncodingMethod: Mandatory if qpsParamName is given. The encoding method
//     used by <QueryParamSync> to synchronize the filter value with a query
//     parameter.
// - qpsParamName: Optional. The name of the query parameter that
//     <QueryParamSync> uses to synchronize the filter value.
// - defaultValue: Optional. Used by <QueryParamSync> as the value to be
//     represented by the absense of the query parameter.
// - pattern: Optional, RegExp. Used by <TextFilter> to validate input.
// - addEmpty: Optional, Boolean. Add a node with the value empty string to a
//     ValueFilter. Be cautious when using this with server filters!
// - values: Mandatory for SelectFilter, Array of {display, value}.
// - EndpointParameter: Optional parameters to use on endpoint request for valuefilter.
const futile = require("@fujitsusweden/futile");

module.exports = futile.deepFreeze({
  veda: {
    name: "Verksamhet",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/veda/filter/departments",
    width: "400",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "departmentvedaid",
  },
  veda_active: {
    name: "Aktiv",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/veda/filter/active",
    width: "100",
    qpsEncodingMethod: "CommaSeparatedStringsAndBooleans",
    qpsParamName: "active",
  },
  user_uid: {
    name: "AnvändarID (UID)",
    component: "TextFilter",
    width: "230",
    qpsEncodingMethod: "Straight",
    qpsParamName: "uid",
    pattern: /^[a-zA-Z]{2,3}[0-9]{5}$/u,
  },
  user_dn: {
    name: "AnvändarID (DN)",
    component: "TextFilter",
    width: "400",
    qpsEncodingMethod: "Straight",
    qpsParamName: "dn",
    pattern: /^CN=[A-ZÅÄÖa-zåäö0-9 -]+(,OU=[A-Za-z0-9 ]+)+(,DC=[a-z]+)+$/u,
  },
  group_sam: {
    name: "GruppID (SAM)",
    component: "TextFilter",
    width: "350",
    qpsEncodingMethod: "Straight",
    qpsParamName: "sam",
    pattern: /^[A-Za-z0-9_]+(-[A-Za-z0-9_]+)*$/u,
  },
  group_dn: {
    name: "GruppID (DN)",
    component: "TextFilter",
    width: "400",
    qpsEncodingMethod: "Straight",
    qpsParamName: "dn",
    pattern: /^CN=[A-Za-z0-9-]+(,OU=[A-Za-z0-9 ]+)+(,DC=[a-z]+)+$/u,
  },
  group_type: {
    name: "Grupptyp",
    // todo: Switch to a component where user can select one or zero options
    //   from a list.
    component: "TextFilter",
    width: "150",
    qpsEncodingMethod: "Straight",
    qpsParamName: "group_type",
    pattern: /^(AK|CLASS|AMNE|PROJ|KURS|PROGRAM)$/u,
  },
  gsuite_chromeos_model: {
    name: "Modell",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/gsuite/filter/ChromeOsModel",
    width: "460",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "model",
  },
  gsuite_chromeos_osversion: {
    name: "OS version",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/gsuite/filter/ChromeOsOperatingSystemVersion",
    width: "150",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "osv",
  },
  gsuite_chromeos_serialnumber: {
    name: "Serienummer",
    component: "TextFilter",
    width: "300",
    qpsEncodingMethod: "Straight",
    qpsParamName: "sn",
    pattern: /^(5CD|M)[A-Za-z0-9-]{7}$/u,
  },
  gsuite_chromeos_firmwareversion: {
    name: "Firmware version",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/gsuite/filter/ChromeOsFirmwareVersion",
    width: "220",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "fwv",
  },
  gsuite_ou_path: {
    name: "Fullständig sökväg OU-grupp",
    component: "TextFilter",
    width: "600",
    qpsEncodingMethod: "Straight",
    qpsParamName: "oupath",
    pattern: /^\/[A-Za-z0-9 -_/]{0,250}$/u,
  },
  gsuite_user_email: {
    name: "Epostaddress",
    component: "TextFilter",
    width: "400",
    qpsEncodingMethod: "Straight",
    qpsParamName: "email",
    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*@?(.se|.com)$/u,
  },
  gsuite_ou_tree: {
    name: "OU-grupp",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/gsuite/filter/ou",
    width: "250",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "oupaths",
  },
  barchart_order_xy: {
    component: "SelectFilter",
    width: "300",
    defaultValue: "x",
    qpsEncodingMethod: "Straight",
    qpsParamName: "barchartorder",
  },
  sccm_app_manufacturers: {
    name: "Applikationstillverkare",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/sccm/filter/applicationManufacturers",
    width: "265",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "appmanufacturer",
  },
  sccm_app_versions: {
    name: "Applikationsnamn och version",
    component: "TextFilter",
    width: "600",
    qpsEncodingMethod: "Straight",
    qpsParamName: "appnameversion",
    pattern: /[^]$/u,
  },
  sccm_os_versions: {
    name: "Os och version",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/sccm/filter/osVersions",
    width: "400",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "osversion",
  },
  sccm_device_types: {
    name: "Enhetstyp",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/sccm/filter/devicetypes",
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "devicetypes",
  },
  sccm_deployed_applications: {
    name: "Applikationsnamn",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/sccm/filter/deployedapplications",
    width: "350",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "deployed_applications",
  },
  sccm_deployed_application_states: {
    name: "Status",
    component: "ValueFilter",
    nodes: [
      { display: "Lyckad", value: "Lyckad" },
      { display: "Pågående", value: "Pågående" },
      {
        display: "Förutsättningar ej uppfyllda",
        value: "Förutsättningar ej uppfyllda",
      },
      { display: "Okänd", value: "Okänd" },
      { display: "Misslyckad", value: "Misslyckad" },
    ],
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "deployed_application_states",
  },
  sccm_deployed_application_config: {
    name: "Installation/Avinstallation",
    component: "ValueFilter",
    nodes: [
      { display: "Installation", value: "Installation" },
      { display: "Avinstallation", value: "Avinstallation" },
    ],
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "deployed_application_config",
  },
  intune_ios_model: {
    name: "Modell",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/mdm/filter/iosModel",
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "iosmodel",
  },
  intune_ios_osmajorversion: {
    name: "Operativsystem",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/mdm/filter/iosOsMajorVersion",
    width: "250",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "iososmajorversion",
  },
  intune_ios_serialnumber: {
    name: "Serienummer",
    component: "TextFilter",
    width: "300",
    qpsEncodingMethod: "Straight",
    qpsParamName: "iossn",
    pattern: /^[A-Za-z0-9-]{12}$/u,
  },
  intune_macos_model: {
    name: "Modell",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/mdm/filter/macosModel",
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "macmodel",
  },
  intune_macos_osmajorversion: {
    name: "Operativsystem",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/mdm/filter/macosOsMajorVersion",
    width: "250",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "macososmajorversion",
  },
  device_cosnumber: {
    name: "COS",
    component: "TextFilter",
    width: "300",
    qpsEncodingMethod: "Straight",
    qpsParamName: "cos",
    pattern: /^[A-Z0-9 -]{1,50}$/iu,
  },
  exit_state: {
    name: "Status",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/ad/filter/exitstate",
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "state",
  },
  truefalse: {
    component: "ValueFilter",
    nodes: [
      { display: "Sant", value: true },
      { display: "Falskt", value: false },
    ],
    width: "100",
    qpsEncodingMethod: "CommaSeparatedStringsAndBooleans",
  },
  invoice_reference: {
    name: "Fakturareferens",
    component: "TextFilter",
    width: "300",
    qpsEncodingMethod: "Straight",
    qpsParamName: "invoicereference",
    pattern: /^\d{3,9}$/iu,
  },
  dmnx_model: {
    name: "Modell",
    component: "ValueFilter",
    nodeEndpoint: "/api/reporting/dmnx/filter/model",
    width: "200",
    qpsEncodingMethod: "CommaSeparatedStrings",
    qpsParamName: "dmnxmodel",
  },
  checkbox: {
    name: "(Checkbox text)", // Override this with <RFilter configName="checkbox" name="Actual text" ...
    component: "CheckBox",
    qpsEncodingMethod: "Boolean",
  },
  intune_asm_tokenid: {
    name: "TokenId",
    component: "TextFilter",
    width: "300",
    qpsEncodingMethod: "Straight",
    qpsParamName: "tokenid",
    pattern: /^[A-Za-z0-9-]{36}$/u,
  },
});
