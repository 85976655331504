<template>
  <div>
    <div class="logobar-container">
      <div class="container">
        <div class="logoitems-container">
          <div class="logo-wrapper pointer" v-on:click="GoToReportList()">
            <img class="logoitem-image" src="@/assets/images/stockholmsstad_logo.svg" />
            <div>
              <h2 style="margin: 0px">Rapport</h2>
              <div class="sans-font">{{ $store.getters.appVersion }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menubar-container">
      <div class="container">
        <div class="menuitems-container" v-if="$store.getters.authenticated">
          <router-link to="/reporting" class="sans-font menuitem">
            <span>Rapporter</span>
          </router-link>
          <router-link to="/releasenotes" class="sans-font menuitem">
            <span>Versionsinformation</span>
          </router-link>
          <router-link to="/myaccount" class="sans-font menuitem">
            <span>Mitt konto</span>
          </router-link>
          <a href="#" style="margin-left: auto" class="sans-font menuitem" v-on:click="Logout()">Logga ut</a>
        </div>
        <div class="menuitems-separator" v-else></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "menubar",
  components: {},
  data() {
    return { showMenu: false };
  },
  methods: {
    GoToReportList() {
      this.$router.push({ path: "/reporting" });
    },
    Logout() {
      this.$store.dispatch("logoutUser");
    },
  },
};
</script>

<style scoped>
.logobar-container {
  width: 100%;
  background-color: #f5f3ee;
}

.logoitems-container {
  display: flex;
  align-items: center;
  height: 153px;
}

.logo-wrapper {
  display: flex;
  align-items: flex-start;
  height: 63px;
}

.logoitem-image {
  width: auto;
  align-self: center;
  padding-right: 63px;
  margin-right: 24px;
  border-right: 1px solid black;
}

.menubar-container {
  background-color: #5d237d;
}

.menubar-separator {
  background-color: #5d237d;
  height: 5px;
}

.menuitems-container {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menuitem {
  color: #ffffff;
  text-decoration: none;
  margin-right: 30px;
}

.menuitem:hover {
  text-decoration: underline;
}
</style>
